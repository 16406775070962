<template>
  <div class="educator-form">
    <b-form @submit.prevent="onSubmit">
      <div class="form-row">
        <div class="col-md-4">
          <div class="image-upload-container">
            <img 
              :src="imagePreview || form.image || '/images/placeholder.png'" 
              class="preview-image"
              alt="Educator profile"
            >
            <div class="image-upload-overlay">
              <label class="btn btn-outline-light mb-0">
                <i class="fa fa-upload"></i> Upload Image
                <input 
                  type="file" 
                  accept="image/*"
                  @change="handleImageUpload" 
                  class="d-none"
                >
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <b-form-group label="Name">
            <b-form-input
              v-model="form.name"
              required
              placeholder="Enter educator's name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Title">
            <b-form-input
              v-model="form.title"
              placeholder="Enter educator's title or role"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Bio">
        <b-form-textarea
          v-model="form.bio"
          placeholder="Enter educator's bio"
          rows="4"
        ></b-form-textarea>
      </b-form-group>

      <div class="form-row">
        <div class="col-md-6">
          <b-form-group label="Website">
            <b-form-input
              v-model="form.website"
              type="url"
              placeholder="https://example.com"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Status">
            <div class="checkbox-wrapper">
              <input type="checkbox" v-model="form.active" id="active-status" class="status-checkbox">
              <label for="active-status" class="status-label">
                Active
              </label>
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="social-links">
        <h5>Social Media Links</h5>
        <div class="form-row">
          <div class="col-md-4">
            <b-form-group label="Facebook">
              <b-form-input
                v-model="form.facebook"
                placeholder="Facebook profile URL"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Instagram">
              <b-form-input
                v-model="form.instagram"
                placeholder="Instagram profile URL"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Twitter">
              <b-form-input
                v-model="form.twitter"
                placeholder="Twitter profile URL"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Videos Section -->
      <div class="videos-section mt-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h5 class="mb-0">Educator Videos</h5>
          <button type="button" class="btn btn-sm btn-primary" @click="showAddVideoModal">
            <i class="fa fa-plus"></i> Add Video
          </button>
        </div>

        <draggable 
          v-model="form.videos" 
          group="videos" 
          @change="handleVideosReorder" 
          handle=".video-drag-handle"
          class="video-list"
        >
          <div v-for="video in form.videos" :key="video.id" class="video-item p-2 mb-2">
            <div class="d-flex align-items-center">
              <button class="btn btn-sm btn-outline-secondary video-drag-handle mr-2">
                <i class="fa fa-bars"></i>
              </button>
              <img :src="video.Thumbnail" class="video-thumbnail mr-3" />
              <div class="video-info flex-grow-1">
                <h6 class="mb-1">{{ video.Title }}</h6>
                <small class="text-muted">{{ video.Section }}</small>
              </div>
              <button 
                class="btn btn-sm btn-outline-danger" 
                @click="removeVideo(video)"
                title="Remove Video"
              >
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </draggable>
      </div>

      <!-- Save Button -->
      <div class="mt-4 d-flex justify-content-end">
        <b-button 
          variant="secondary" 
          class="mr-2"
          @click="handleCancel"
        >
          Cancel
        </b-button>
        <b-button 
          variant="primary" 
          @click="onSubmit"
          :disabled="!form.name"
        >
          {{ mode === 'new' ? 'Create Educator' : 'Save Changes' }}
        </b-button>
      </div>
    </b-form>

    <!-- Add Video Modal -->
    <b-modal
      id="modal-add-video"
      title="Add Video"
      size="lg"
      hide-footer
    >
      <div class="video-search mb-3">
        <b-form-input
          v-model="videoSearch"
          placeholder="Search videos..."
          @input="filterVideos"
        ></b-form-input>
      </div>
      <div class="video-search-results" style="max-height: 400px; overflow-y: auto;">
        <div
          v-for="video in filteredVideos"
          :key="video.id"
          class="video-search-item p-2"
          @click="addVideo(video)"
        >
          <div class="d-flex align-items-center">
            <img :src="video.Thumbnail" class="video-thumbnail mr-3" />
            <div>
              <h6 class="mb-1">{{ video.Title }}</h6>
              <small class="text-muted">{{ video.Section }}</small>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { storage, db } from "@/db.js";
import draggable from 'vuedraggable';

export default {
  name: "EducatorForm",
  components: {
    draggable
  },
  props: {
    educator: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      required: true,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      form: {
        name: "",
        title: "",
        bio: "",
        website: "",
        facebook: "",
        instagram: "",
        twitter: "",
        image: "",
        active: true,
        videos: []
      },
      imagePreview: null,
      imageFile: null,
      videoSearch: "",
      allVideos: [],
      filteredVideos: [],
      assignedPosts: []
    };
  },
  watch: {
    educator: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.form = { 
            ...newVal,
            videos: []
          };
          // Load assigned videos when educator changes
          if (this.mode === 'edit') {
            this.loadAssignedVideos();
          }
        }
      }
    }
  },
  async mounted() {
    // Load all videos for the search modal
    try {
      const snapshot = await db.collection("posts").get();
      this.allVideos = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // If editing, load assigned videos
      if (this.mode === 'edit' && this.educator) {
        await this.loadAssignedVideos();
      }
    } catch (error) {
      console.error("Error loading videos:", error);
    }
  },
  methods: {
    handleCancel() {
      // Close the appropriate modal based on mode
      this.$bvModal.hide(this.mode === 'new' ? 'modal-new-educator' : 'modal-edit-educator');
      this.$emit('cancel');
    },
    async loadAssignedVideos() {
      try {
        // Query posts where this educator is assigned
        const snapshot = await db.collection("posts")
          .where("Educator", "==", this.educator.id)  // Changed from educators array to Educator field
          .get();
        
        this.assignedPosts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Update form videos with assigned posts
        this.form.videos = this.assignedPosts;
      } catch (error) {
        console.error("Error loading assigned videos:", error);
        this.$bvToast.toast('Error loading assigned videos', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.imageFile = file;
      this.imagePreview = URL.createObjectURL(file);
    },
    async uploadImage() {
      if (!this.imageFile) return this.form.image;

      const timestamp = Date.now();
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`educators/${timestamp}_${this.imageFile.name}`);

      try {
        const snapshot = await imageRef.put(this.imageFile);
        const downloadURL = await snapshot.ref.getDownloadURL();
        return downloadURL;
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    },
    async onSubmit() {
      try {
        if (this.imageFile) {
          this.form.image = await this.uploadImage();
        }

        // Emit the form data without the videos array
        const { videos, ...formData } = this.form;
        this.$emit("submit", formData);
        
        // Close the modal
        this.$nextTick(() => {
          this.$bvModal.hide('modal-new-educator');
          this.$bvModal.hide('modal-edit-educator');
        });
      } catch (error) {
        console.error("Error submitting form:", error);
        this.$bvToast.toast('Failed to save educator. Please try again.', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },
    showAddVideoModal() {
      this.videoSearch = "";
      this.filterVideos();
      this.$bvModal.show('modal-add-video');
    },
    filterVideos() {
      const search = this.videoSearch.toLowerCase();
      this.filteredVideos = this.allVideos.filter(video => 
        video.Title?.toLowerCase().includes(search) ||
        video.Section?.toLowerCase().includes(search)
      );
    },
    addVideo(video) {
      // Check if video is already in the list
      if (!this.form.videos.some(v => v.id === video.id)) {
        this.form.videos.push(video);
        
        // If in edit mode, update the post's educators array
        if (this.mode === 'edit') {
          this.updatePostEducators(video.id, true);
        }
      }
      this.$bvModal.hide('modal-add-video');
    },
    removeVideo(video) {
      const index = this.form.videos.findIndex(v => v.id === video.id);
      if (index > -1) {
        this.form.videos.splice(index, 1);
        
        // If in edit mode, update the post's educators array
        if (this.mode === 'edit') {
          this.updatePostEducators(video.id, false);
        }
      }
    },
    async updatePostEducators(postId, add) {
      try {
        const postRef = db.collection("posts").doc(postId);
        const postDoc = await postRef.get();
        
        if (postDoc.exists) {
          if (add) {
            // Add educator reference
            await postRef.update({
              Educator: this.educator.id,  // Changed to use Educator field instead of array
              EducatorName: this.educator.name
            });
          } else {
            // Remove educator reference
            await postRef.update({
              Educator: null,
              EducatorName: null
            });
          }
        }
      } catch (error) {
        console.error("Error updating post educators:", error);
        this.$bvToast.toast('Error updating video assignments', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },
    handleVideosReorder(event) {
      // The draggable component will automatically update form.videos
      // We can add additional logic here if needed
    }
  }
};
</script>

<style>
.educator-form {
  color: #fff;
}

.image-upload-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
  background: #1c1c1c;
}

.preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.image-upload-container:hover .image-upload-overlay {
  opacity: 1;
}

.social-links {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #444;
}

.social-links h5 {
  color: #fff;
  margin-bottom: 1rem;
}

/* Form Control Styles */
.form-control {
  background: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
}

.form-control:focus {
  background: #2c2c2c;
  border-color: #3498db;
  color: #fff;
  box-shadow: none;
}

.form-control::placeholder {
  color: #666;
}

.custom-switch .custom-control-label::before {
  background-color: #666;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
}

label {
  color: #fff;
  font-weight: 500;
}

@media (max-width: 767px) {
  .image-upload-container {
    padding-top: 75%;
  }
  
  .form-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-checkbox {
  width: 16px;
  height: 16px;
  margin: 0;
}

.status-label {
  color: #fff;
  user-select: none;
  margin: 0;
  cursor: pointer;
}

.video-list {
  min-height: 50px;
  background: #1c1c1c;
  border-radius: 4px;
  border: 1px solid #444;
}

.video-item {
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  margin: 8px;
}

.video-thumbnail {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
}

.video-drag-handle {
  cursor: move;
  opacity: 0.6;
}

.video-drag-handle:hover {
  opacity: 1;
}

.video-search-item {
  cursor: pointer;
  border-bottom: 1px solid #444;
  transition: background-color 0.2s ease;
}

.video-search-item:hover {
  background-color: #2c2c2c;
}

.video-search-item:last-child {
  border-bottom: none;
}
</style> 