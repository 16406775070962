<template>
  <div>
    <b-table 
      striped 
      hover 
      :items="users" 
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
    >
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      @change="handlePageChange"
    ></b-pagination>
  </div>
</template>

<script>
const request = require("request");

export default {
  data() {
    return {
      users: [],
      fields: [
        "displayName",
        "email",
        "customClaims.membershipLevel",
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      nextPageToken: null
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(pageToken = null) {
      const _this = this;
      const url = new URL("https://us-central1-thedjsvault-307019.cloudfunctions.net/app/api/users");
      if (pageToken) {
        url.searchParams.append('pageToken', pageToken);
      }
      url.searchParams.append('pageSize', this.perPage);

      request(
        url.toString(),
        function (error, response) {
          if (error) {
            console.error('Error fetching users:', error);
            return;
          }
          if (!response || !response.body) {
            console.error('Invalid response received');
            return;
          }
          try {
            const data = JSON.parse(response.body);
            if (!data || !data.users) {
              console.error('Invalid data format received');
              return;
            }
            _this.users = data.users;
            _this.nextPageToken = data.nextPageToken;
            _this.totalRows = _this.users.length;
          } catch (parseError) {
            console.error('Error parsing response:', parseError);
          }
        }
      );
    },
    handlePageChange(page) {
      if (page > this.currentPage && this.nextPageToken) {
        this.getUsers(this.nextPageToken);
      } else if (page < this.currentPage) {
        // Handle going back to previous page
        this.getUsers();
      }
      this.currentPage = page;
    }
  },
};
</script>

<style>
</style>